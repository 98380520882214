import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-bill-history-for-admin',
  templateUrl: './bill-history-for-admin.component.html',
  styleUrls: ['./bill-history-for-admin.component.css']
})
export class BillHistoryForAdminComponent implements OnInit {

  lawyerCode: number
  billHistoryForAdminDataList: []
  isLoading: boolean = false;
  IsValidationErrorMessageShown = false; //:
  searchDate: any
  lawyerinfodata: any
  paymentInfoData:  any [] = [];
  erroMassage = ''

  constructor(private billInfoService: BillInformationService,
    public app: AppComponent,
    private router: Router
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit() {
    this.searchDate = new Date();

  }



  

  getbillhistoryForAdmin(id: number) {
    if (!this.lawyerCode && typeof this.lawyerCode !== 'number') {
      this.IsValidationErrorMessageShown = true;
      return
    }
    this.isLoading = true
    if (this.lawyerCode) {
      this.billInfoService.getBitHistoryForAdmin(this.lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
          this.billHistoryForAdminDataList = data;
          this.lawyerinfodata = data.lawyerInfo
          this.paymentInfoData = data.paymentInfo
          this.isLoading = false
          this.erroMassage  = ''
    
        },
        error: (error) => {
          // console.log('Error:', error.error.message);
          this.isLoading = false
          this.lawyerinfodata = []
          this.paymentInfoData = []
          this.erroMassage  = error.error.message
        }
      })
    }
  }



}
