import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';

@Component({
  selector: 'app-payment-message',
  templateUrl: './payment-message.component.html',
  styleUrls: ['./payment-message.component.css']
})
export class PaymentMessageComponent implements OnInit {

  paymentMessageList: []
  isLoading: boolean = false;
  constructor(
    private billInfoService: BillInformationService,
    public app:AppComponent,
    private router : Router
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  }

  ngOnInit() {
    this.getPaymentMessage(0)

  }


  getPaymentMessage(id =0) {
    this.isLoading =true
    const lawyerCode = localStorage.getItem('loggedin_lawyercode');
    if (lawyerCode) {
      this.billInfoService.getPayment_Message(+lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
          this.paymentMessageList = data;
          this.isLoading =false
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      })
    }
  }

}
