import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LeftsidebarComponent } from './leftsidebar/leftsidebar.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HomecontentComponent } from './pages/homecontent/homecontent.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { JusticeWiseComponent } from './pages/case_list/justice-wise/justice-wise.component';
import { CourtWiseComponent } from './pages/case_list/court-wise/court-wise.component';
import { AdCourtWiseComponent } from './pages/case_list/ad-court-wise/ad-court-wise.component';
import { AdPageWiseComponent } from './pages/case_list/ad-page-wise/ad-page-wise.component';
import { CauseListSearchComponent } from './pages/search_your_case/cause-list-search/cause-list-search.component';
import { AdCauseListSearchComponent } from './pages/search_your_case/ad-cause-list-search/ad-cause-list-search.component';
import { OurServicesComponent } from './pages/our_services/our-services/our-services.component';
import { SoftwareAndWebDevelopmenComponent } from './pages/our_services/software-and-web-developmen/software-and-web-developmen.component';
import { AdvocateComponent } from './pages/service_user/advocate/advocate.component';
import { OrganizationAndOthersComponent } from './pages/service_user/organization-and-others/organization-and-others.component';
import { SearchListByDateHighcourtComponent } from './pages/search_list_user_menu/search-list-by-date-highcourt/search-list-by-date-highcourt.component';
import { SearchListByDateAppelateComponent } from './pages/search_list_user_menu/search-list-by-date-appelate/search-list-by-date-appelate.component';
import { TotalCaseListHighcourtComponent } from './pages/search_list_user_menu/total-case-list-highcourt/total-case-list-highcourt.component';
import { TotalCaseListAppelateComponent } from './pages/search_list_user_menu/total-case-list-appelate/total-case-list-appelate.component';
import { NewCaseRequestHighcourtComponent } from './pages/search_list_user_menu/new-case-request-highcourt/new-case-request-highcourt.component';
import { NewCaseRequestAppelateComponent } from './pages/search_list_user_menu/new-case-request-appelate/new-case-request-appelate.component';
import { UserDetailsComponent } from './pages/search_list_user_menu/user-details/user-details.component';
import { CauseListSearchHistoryComponent } from './pages/search_your_case/cause-list-search-history/cause-list-search-history.component';
import { CauseListSearchCourtHistoryComponent } from './pages/search_your_case/cause-list-search-court-history/cause-list-search-court-history.component';
import { CauseListSearchPageHistoryComponent } from './pages/search_your_case/cause-list-search-page-history/cause-list-search-page-history.component';
import { AdCauseListSearchHistoryComponent } from './pages/search_your_case/ad-cause-list-search-history/ad-cause-list-search-history.component';
import { AdCauseListSearchCourtHistoryComponent } from './pages/search_your_case/ad-cause-list-search-court-history/ad-cause-list-search-court-history.component';
import { AdCauseListSearchPageHistoryComponent } from './pages/search_your_case/ad-cause-list-search-page-history/ad-cause-list-search-page-history.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AutoLogoutComponent } from './auto-logout/auto-logout.component';
import { ScrollNewsComponent } from './pages/scroll-news/scroll-news.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SafePipe } from './safe.pipe';
import { NotificationComponent } from './pages/notification/notification.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { NewCaseEntryInfoComponent } from './pages/search_list_user_menu/new-case-entry-info/new-case-entry-info.component';
import { BillSubmitMessageComponent } from './pages/search_list_user_menu/bill_information/bill-submit-message/bill-submit-message.component';
import { BillPaymentReminderComponent } from './pages/search_list_user_menu/bill_information/bill-payment-reminder/bill-payment-reminder.component';
import { PaymentMessageComponent } from './pages/search_list_user_menu/bill_information/payment-message/payment-message.component';
import { HtmlContentRenderModal } from './alert/htmlContentRender';
import { BillSubmitMessageSadminComponent } from './pages/search_list_user_menu/bill_information/bill-submit-message-sadmin/bill-submit-message-sadmin.component';
import { PaymentMessageSadminComponent } from './pages/search_list_user_menu/bill_information/payment-message-sadmin/payment-message-sadmin.component';
import { BillReminderSadminComponent } from './pages/search_list_user_menu/bill_information/bill-reminder-sadmin/bill-reminder-sadmin.component';
import { CommonModule } from '@angular/common';
import { BillHistoryForAdminComponent } from './pages/search_list_user_menu/bill_information/bill-history-for-admin/bill-history-for-admin.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftsidebarComponent,
    RightsidebarComponent,
    HomecontentComponent,
    AboutComponent,
    ContactComponent,
    JusticeWiseComponent,
    CourtWiseComponent,
    AdCourtWiseComponent,
    AdPageWiseComponent,
    CauseListSearchComponent,
    AdCauseListSearchComponent,
    OurServicesComponent,
    SoftwareAndWebDevelopmenComponent,
    AdvocateComponent,
    OrganizationAndOthersComponent,
    SearchListByDateHighcourtComponent,
    SearchListByDateAppelateComponent,
    TotalCaseListHighcourtComponent,
    TotalCaseListAppelateComponent,
    NewCaseRequestHighcourtComponent,
    NewCaseRequestAppelateComponent,
    UserDetailsComponent,
    CauseListSearchHistoryComponent,
    CauseListSearchCourtHistoryComponent,
    CauseListSearchPageHistoryComponent,
    AdCauseListSearchHistoryComponent,
    AdCauseListSearchCourtHistoryComponent,
    AdCauseListSearchPageHistoryComponent,
    ScrollTopComponent,
    AlertComponent,
    AutoLogoutComponent,
    ScrollNewsComponent,
    SafePipe,
    NotificationComponent,
    PrivacyPolicyComponent,
    NewCaseEntryInfoComponent,
    BillSubmitMessageComponent,
    BillPaymentReminderComponent,
    PaymentMessageComponent,
    HtmlContentRenderModal,
    BillSubmitMessageSadminComponent,
    PaymentMessageSadminComponent,
    BillReminderSadminComponent,
    BillHistoryForAdminComponent,
  
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    DatePickerModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleModalModule.forRoot({container: "modal-container"}),
    DeviceDetectorModule.forRoot()
        
  ],
  entryComponents: [
    AlertComponent,
    HtmlContentRenderModal
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
