import { Component, OnInit } from '@angular/core';
import { CasetodayService } from '../../../casetoday.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  userdata:any = [];
  totalcaseliscount:any = [];
  todaytotalcaseliscount:any = [];
  search_code:any;

  errorMessage: string;
  isLoading: boolean = true;

  current_username: string;
  current_laywerCode: string;
  is_admin: boolean = false;

  constructor(
    public rest:CasetodayService, 
    private route: ActivatedRoute, 
    private router: Router,
    public app:AppComponent
) { 

  if (!this.app.loggedIn()) {
    //this.location.replaceState('/');
    this.router.navigate(['../']);
  }  

}


  ngOnInit() {

    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin(); 

    this.getUserDetails(this.current_laywerCode);
    this.getTotalcaselisthighcourtcount(this.current_laywerCode);
    this.getTodayTotalcaselisthdcount(this.current_laywerCode);
  }


  getUserDetails(current_laywerCode) {
    let postData = {
      search_code: current_laywerCode,
    }

    this.rest
        .getUserDetails(postData)
        .subscribe(
          userdata => {
                this.userdata = userdata
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


  getTotalcaselisthighcourtcount(current_laywerCode) {
    let postData = {
      search_code: current_laywerCode,
    }

    this.rest
        .getTotalcaselisthighcourtcount(postData)
        .subscribe(
          totalcaseliscount => {
                this.totalcaseliscount = totalcaseliscount
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  getTodayTotalcaselisthdcount(current_laywerCode) {
    let postData = {
      search_code: current_laywerCode,
    }

    this.rest
        .getTodayTotalcaselisthdcount(postData)
        .subscribe(
          todaytotalcaseliscount => {
                this.todaytotalcaseliscount = todaytotalcaseliscount
                console.log(todaytotalcaseliscount);
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
