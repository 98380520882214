import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface HtmlRenderModel {
  title: string;
  message: string;
}

@Component({
  selector: 'modal',
  templateUrl: './htmlContentRender.html',
  styleUrls: ['./htmlContentRender.css']
})
export class HtmlContentRenderModal extends SimpleModalComponent<HtmlRenderModel, null> implements HtmlRenderModel {
  title: string;
  message: string;
  constructor() {
    super();
  }
}
