import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

//const endpoint = 'http://192.168.0.106:8000/api/';
//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
//siddiqueenterprise.com
const endpoint = environment.adminBdlawservice;
//const endpoint = "https://admin.siddiqueenterprise.com/public/api/";
//const endpoint = "https://admin.siddiqueenterprise.com/public/api/";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class JusticelistService {
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getJusticelist(postData): Observable<any> {
    // return this.http.post(endpoint + 'justicelist', postData).pipe(
    //   map(this.extractData));

    return this.http
      .post(endpoint + "justicelist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getDraftStatus(postData): Observable<any> {
    if (postData.seachjusticeDate.getFullYear() == new Date().getFullYear()) {
      return this.http
        .post(endpoint + "is_draft_copy", postData, httpOptions)
        .pipe(map(this.extractData));
    }
  }

  getJusticewise(postData): Observable<any> {
    return this.http
      .post(endpoint + "justicewise", postData)
      .pipe(map(this.extractData));
  }

  getjusticeWiseCourtName(postData): Observable<any> {
    return this.http
      .post(endpoint + "justiceWiseCourtName", postData)
      .pipe(map(this.extractData));
  }

  getCourtWiseJusticeName(postData): Observable<any> {
    return this.http.post(
      endpoint + "courtwiseJusticeNameforJusticeWise",
      postData,
      httpOptions
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
