import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-and-web-developmen',
  templateUrl: './software-and-web-developmen.component.html',
  styleUrls: ['./software-and-web-developmen.component.css']
})
export class SoftwareAndWebDevelopmenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
