import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodService {

  constructor() { }

  restrictNonNumeric(event: KeyboardEvent): boolean {
    const charCode = event.which || event.keyCode;
    const isValid = charCode >= 48 && charCode <= 57;

    if (!isValid) {
      event.preventDefault(); // Prevent invalid input
    }

    return !isValid; // Return true if invalid character is entered
  }

  // Validate the input and check for numeric values
  validateNumericInput(value: string): { cleanedValue: string; hasError: boolean } {
    const cleanedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    const hasError = cleanedValue === ''; // Set error flag if value is empty
    return { cleanedValue, hasError };
  }


}
